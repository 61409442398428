import * as React from "react";
import Icon from "./Icon";

const COMMON_LIST_ELEM_CLASSES = `
  w-full p-2
  text-xl font-light
  border-slate-200 border-2
  group-focus-within:border-slate-300
`;

interface PropTypes {
  value: string;
  thumb?: string;
  isLast: boolean;
  selected: boolean;
  href?: string;
  style: "item" | "info" | "error";
  onClick?: React.MouseEventHandler;
}

export default function ListItem({
  value,
  thumb,
  isLast,
  selected,
  style,
  href,
  onClick,
}: PropTypes) {
  const iconClassName = ["ml-2"];
  const textClassName = [
    COMMON_LIST_ELEM_CLASSES,
    "relative",
    "border-t-0",
    isLast ? "rounded-b-lg" : "",
  ];

  switch (style) {
    case "item":
      textClassName.push(selected ? "bg-slate-700 text-slate-100" : "");
      break;
    case "info":
      textClassName.push("text-slate-500 italic");
      break;
    case "error":
      textClassName.push("text-red-500");
      iconClassName.push("text-red-500");
      break;
  }

  let thumbElem = <></>;
  if (thumb != null) {
    thumbElem = (
      <img src={thumb} alt="thumbnail" className="inline w-full"></img>
    );
  }

  return (
    <a className={`relative block`} onClick={onClick} href={href}>
      {style == "error" ? (
        <Icon type="exclamation-circle" className={iconClassName.join(" ")} />
      ) : null}
      <div className={textClassName.join(" ")}>
        <div className="inline-block w-8">{thumbElem}</div>
        <div className="inline-block pl-1">{value}</div>
      </div>
    </a>
  );
}
