import { debounce, isEmpty } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import Icon from "./Icon";
import ListItem from "./ListItem";
import Trie, { TrieNode } from "../lib/Trie";

// staging
// const HOST = "http://scraper.local:3001";
const LOG = false;
const HOST = "";
const COMMON_LIST_ELEM_CLASSES = `
  w-full p-2 pl-8
  text-xl font-light
  border-slate-200 border-2
  group-focus-within:border-slate-300
`;
const SVG_ELEM_CLASSES = `
  stroke-slate-500 fill-slate-500
`;
const FOCUSABLE_SVG_ELEM_CLASSES = `
  ${SVG_ELEM_CLASSES}
  group-focus-within:stroke-slate-800 group-focus-within:fill-slate-800
`;
const INPUT_ELEM_CLASSES = `
  text-slate-500 group-focus-within:text-slate-800
  placeholder-slate-500 group-focus-within:placeholder-slate-800
  placeholder:font-extralight
`;

function log_info(...args) {
  if (LOG) {
    console.log(...args);
  }
}
function log_error(...args) {
  if (LOG) {
    console.error(...args);
  }
}

interface PropTypes {
  isServerRendered?: boolean;
}
interface ServerResponse {
  users: {
    id: number;
    name: string;
    url_name: string;
    thumb?: string;
    show_path: string;
  }[];
}
type TrieValue = [number, string];
type TrieType = Trie<TrieValue>;
type TrieNodeType = TrieNode<TrieValue>;
export default function UserSearchBar({ isServerRendered }: PropTypes) {
  isServerRendered = !!isServerRendered;
  const [userName, setUserName] = useState("");
  const [userList, setUserList] = useState<ServerResponse["users"]>([]);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [listHovered, setListHovered] = useState(false);
  const [pendingRequest, setPendingRequest] = useState<AbortController | null>(
    null
  );
  const [typingSettled, setTypingSettled] = useState(true);
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(
    isServerRendered
      ? false
      : document.activeElement == inputRef.current || true
  );

  const clearResults = useCallback(() => {
    setUserList([]);
    setErrorMessage(null);
    setSelectedIdx(null);
  }, []);

  const cancelPendingRequest = useCallback(async () => {
    if (pendingRequest) {
      setPendingRequest(null);
      pendingRequest.abort();
    }
  }, [pendingRequest, setPendingRequest]);

  const sendSearchRequest = useCallback(
    (userName) => {
      cancelPendingRequest();

      const controller = new AbortController();
      setPendingRequest(controller);

      async function sendRequest() {
        try {
          let req = await fetch(
            `${HOST}/api/fa/search_user_names?name=${userName}`,
            {
              signal: controller.signal,
            }
          );
          if (req.status != 200) {
            setErrorMessage(`error loading users: ${await req.body}`);
          } else {
            let gotUserList = await req.json();
            setPendingRequest(null);
            setUserList(gotUserList.users);
          }
        } catch (err) {
          if (!err.message.includes("aborted")) {
            log_error("error loading user trie: ", err);
            setPendingRequest(null);
            setErrorMessage(`error loading users: ` + err.message);
          }
        }
      }
      sendRequest();

      return () => controller.abort();
    },
    [cancelPendingRequest, setPendingRequest]
  );

  const searchForUser = useCallback(
    (userName: string) => {
      setUserName(userName);
      if (isEmpty(userName)) {
        clearResults();
      } else {
        sendSearchRequest(userName);
      }
    },
    [clearResults, sendSearchRequest]
  );

  const searchForUserDebounced = useCallback(
    debounce(async (userName) => {
      log_info("sending search for ", userName);
      setTypingSettled(true);
      searchForUser(userName);
    }, 250),
    [setTypingSettled, searchForUser]
  );

  function invokeIdx(idx) {
    const user = userList[idx];
    if (user) {
      log_info("selecting user: ", user);
      setUserName(user.name);
      inputRef.current.value = user.name;
      window.location.href = user.show_path;
    }
  }

  function invokeSelected() {
    if (selectedIdx != null) {
      invokeIdx(selectedIdx);
    }
  }

  function onSearchInputKeyDown(event) {
    const { code, shiftKey } = event;
    switch (code) {
      case "Tab":
        if (shiftKey) {
          selectPrevListElem();
        } else {
          selectNextListElem();
        }
        break;
      case "ArrowDown":
        selectNextListElem();
        break;
      case "ArrowUp":
        selectPrevListElem();
        break;
      case "Enter":
        invokeSelected();
        break;
      default:
        return;
    }

    event.preventDefault();
  }

  function selectNextListElem() {
    setNewIdxTruncated(selectedIdx == null ? 0 : selectedIdx + 1);
  }

  function selectPrevListElem() {
    setNewIdxTruncated(selectedIdx == null ? -1 : selectedIdx - 1);
  }

  function setNewIdxTruncated(newIdx) {
    if (userList.length == 0) {
      newIdx = null;
    } else {
      if (newIdx >= userList.length) {
        newIdx = 0;
      } else if (newIdx < 0) {
        newIdx = userList.length - 1;
      }
    }
    setSelectedIdx(newIdx);
  }

  const errorShown = isFocused && !isEmpty(errorMessage);
  const infoShown =
    isFocused &&
    !isEmpty(userName) &&
    pendingRequest == null &&
    typingSettled &&
    userList.length == 0;
  const itemsShown =
    !isEmpty(userName) && (isFocused || listHovered) && userList.length > 0;
  const anyShown = infoShown || errorShown || itemsShown;

  function UserSearchBarItems() {
    return (
      <div
        onPointerOver={() => {
          log_info("hovered list");
          setListHovered(true);
        }}
        onPointerLeave={() => {
          log_info("left list");
          setListHovered(false);
        }}
      >
        {errorShown ? (
          <ListItem
            key="error"
            isLast={!infoShown && userList.length == 0}
            selected={false}
            style="error"
            value={errorMessage}
          />
        ) : null}
        {infoShown ? (
          <ListItem
            key="info"
            isLast={!itemsShown}
            selected={false}
            style="info"
            value="No users found"
          />
        ) : null}
        {itemsShown
          ? userList.map(({ name, thumb, show_path }, idx) => (
              <ListItem
                key={"name-" + name}
                isLast={idx == userList.length - 1}
                selected={idx == selectedIdx}
                style="item"
                value={name}
                thumb={thumb}
                href={show_path}
              />
            ))
          : null}
      </div>
    );
  }

  return (
    <div
      className="group mx-auto w-full p-2
              sm:p-2 sm:border-2 sm:border-slate-100  sm:max-w-md rounded-xl
              focus-within:border-slate-200 focus-within:shadow-md
              transition-all duration-1000
              "
    >
      <label className={`relative block ${INPUT_ELEM_CLASSES}`}>
        <Icon
          type="magnifying-glass"
          className={`ml-2 ${FOCUSABLE_SVG_ELEM_CLASSES}`}
        />
        {pendingRequest != null ? (
          <Icon type="spinner" className={`right-2  ${SVG_ELEM_CLASSES}`} />
        ) : null}
        <input
          autoFocus
          className={[
            COMMON_LIST_ELEM_CLASSES,
            INPUT_ELEM_CLASSES,
            "outline-none rounded-lg",
            "placeholder:italic bg-slate-100",
            anyShown ? "rounded-b-none" : null,
          ].join(" ")}
          placeholder={"Search FurAffinity Users"}
          defaultValue={userName}
          onChange={(v) => {
            setTypingSettled(false);
            searchForUserDebounced(v.target.value);
          }}
          onKeyDown={(v) => {
            setIsFocused(true);
            onSearchInputKeyDown(v);
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={inputRef}
        />
      </label>
      <UserSearchBarItems />
    </div>
  );
}
